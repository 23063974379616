import React from "react"

export default function Home() {
  return  (
          <div class="container">
            <div class="box">
              <div>
                <img src="img/enista_logo_transparent.svg" alt="Enista" width="100%"/>
              </div>
            </div>
          </div>
  )  
}
